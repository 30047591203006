import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Image, Flex, Text } from "theme-ui";
import CurrentPodcastContext from "../../../../hooks/currentPodcastContext";
import PlayIcon from "../../../../assets/img/_bottoni/play.inline.svg";
import PauseIcon from "../../../../assets/img/_bottoni/pause.inline.svg";
import PodcastThumb from "../../../../assets/img/_teaser/anteprima.png";
import theme from "../../../../gatsby-plugin-theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";
import Collapsible from "react-collapsible";
import { Plus } from "react-feather";
import PodcastPlayer from "../../Player";

const PodcastListItem = ({ podcast }) => {
  const [openPlayer, setOpenPlayer] = useState(false);
  const primary = getColor(theme, "primary");
  const secondary = getColor(theme, "secondary");

  const { currentPodcast, setCurrentPodcast } = useContext(
    CurrentPodcastContext
  );

  const handleSetCurrentPodcast = () => {
    setCurrentPodcast({
      ...podcast,
      play: currentPodcast.id === podcast.id ? !currentPodcast.play : true,
    });

    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer.push({
        event:
          currentPodcast.id === podcast.id
            ? !currentPodcast.play === true
              ? "clic_play"
              : "clic_pausa"
            : "clic_play",
      });
    }
  };

  const _renderTrigger = (text, body) => {
    return (
      <Box
        sx={{
          position: "relative",
          backgroundColor: "dark",
          marginTop: "1px",
          "&:before": {
            content: "''",
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            zIndex: -1,
            mt: "-1px",
            mb: "-1px",
            mr: "0px",
            ml: "0px",
            borderRadius: "inherit",
            background: `linear-gradient(to right, ${primary}, ${secondary})`,
            cursor: "pointer",
          },
        }}
      >
        <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Grid sx={{ py: [2] }} columns={["30px 40px auto"]} gap={[2]}>
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "start",
                cursor: "pointer",
              }}
            >
              {currentPodcast.id === podcast.id && currentPodcast.play ? (
                <PauseIcon />
              ) : (
                <PlayIcon />
              )}
            </Flex>
            <Flex
              sx={{
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Image src={PodcastThumb} alt="Podcast Thumbnail" />
            </Flex>
            <Flex
              sx={{
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Text
                sx={{
                  cursor: "pointer",
                  fontSize: [2, 3],
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {podcast.kicker.replace("Estratto episodio","").replace("Anticipazione episodio","")}
                {podcast.title}
              </Text>
            </Flex>
          </Grid>
          <Flex
            sx={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="plus-icon"
          >
            <Plus />
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    currentPodcast && (
      <>
        <Box
          sx={{
            ".plus-icon": {
              transition: "transform .3s linear",
              svg: {
                stroke: "primary",
                transition: "stroke .3s linear",
              },
            },
            ".is-open": {
              ".plus-icon": {
                transform: "rotate(135deg)",

                svg: {
                  stroke: "secondary",
                  transition: "stroke .3s linear",
                },
              },
            },
          }}
        >
          <Collapsible
            open={currentPodcast.id === podcast.id}
            handleTriggerClick={(e) => handleSetCurrentPodcast(e)}
            onOpening={() => setOpenPlayer(true)}
            onClosing={() => setOpenPlayer(false)}
            transitionTime={200}
            trigger={_renderTrigger(podcast)}
          >
            {openPlayer && (
              <Box sx={{ my: [6, 8] }}>
                <PodcastPlayer />
              </Box>
            )}
          </Collapsible>
        </Box>
      </>
    )
  );
};

export default PodcastListItem;
