import { Box, Text, Container, Flex, Grid } from "@theme-ui/components";
import Img from "gatsby-image";
import React, { useEffect, useRef, useState } from "react";

import { Mousewheel, Pagination, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "/node_modules/swiper/swiper.min.css";
import Speaker from "./Speaker";

import theme from "../../gatsby-plugin-theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";

const SpeakerSection = ({ block }) => {
  const primary = getColor(theme, "primary");
  const secondary = getColor(theme, "secondary");
  console.log(block);
  const { speaker, title } = block;

  return (
    <Grid
    columns={["1fr","1fr","1fr","165px auto"]}
      gap={[0]}
      sx={{
        gridRow: [2, 2, 2, 1],
        mt: ["3px"],
        mb: ["3px"],
        overscrollBehaviorX: "contain",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          zIndex: -1,
          mt: "-3px",
          mb: "-3px",
          mr: "0px",
          ml: "0px",
          borderRadius: "inherit",
          background: `linear-gradient(to bottom, ${primary}, ${secondary})`,
        },
      }}
    >
      <Box
        sx={{
          position: ["relative", "relative", "relative"],
          left: 0,
          top: 0,
          minWidth: "162px",
          height: "100%",
          backgroundColor: "dark",
          px: [3, 4],
          py: [4, 7],
          zIndex: "1",
          background: `linear-gradient(to bottom, ${primary}, ${secondary})`,
          "&:before": {
            content: "''",
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            zIndex: -1,
            mt: "0px",
            mb: ["3px", "3px","3px", "0px"],
            mr: ["0px", "0px","0px", "3px"],
            ml: "0px",
            borderRadius: "inherit",
            backgroundColor: "primary",
          },
        }}
      >
        <Text
          as="h2"
          sx={{
            width: "80%",
            mt: [0],
            mb: [0, 0, 0, 2],
            fontWeight: "500",
            lineHeight: 1,
            fontSize: [7, 8, 9, 11],
            textTransform: "uppercase",
            transform: ["unset", "unset","unset", "rotate(270deg)"],
            position: ["relative", "relative","relative", "absolute"],
            left: 0,
            bottom: [0, 0,0, 8],
            color: "dark",
          }}
        >
          {title}
        </Text>
      </Box>
      <Box
        sx={{
          position: "relative",
          background: "dark",
          zIndex: "0",
          ml: [0, 0],
          maxWidth : ["100%","100%","100%"]
        }}
      >
        <Speaker data={speaker} />
      </Box>
    </Grid>
  );
};

export default SpeakerSection;
