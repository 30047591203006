import React from "react";
import { Box } from "theme-ui";

const SoundWaveLoader = () => {
  return (
    <Box
      sx={{
        ".wrapper": {
          width: "54px",
          height: "25px",
          position: "relative",
          left: "50%",
          marginLeft: "-27px",
        },
        "#preloader_1": { position: "relative", height:"50%" },
        "#preloader_1 span": {
          display: "block",
          bottom: "0px",
          width: "9px",
          height: "5px",
          background: "#01D7E2",
          position: "absolute",
          animation: "preloader_1 1.5s  infinite ease-in-out",
        },
        "#preloader_1 span:nth-child(2)": {
          left: "11px",
          animationDelay: ".2s",
        },
        "#preloader_1 span:nth-child(3)": {
          left: "22px",
          animationDelay: ".4s",
        },
        "#preloader_1 span:nth-child(4)": {
          left: "33px",
          animationDelay: ".6s",
        },
        "#preloader_1 span:nth-child(5)": {
          left: "44px",
          animationDelay: ".8s",
        },
        "@keyframes preloader_1": {
          "0%": {
            height: "5px",
            transform: "translateY(0px)",
            background: "#01D7E2",
          },
          "25%": {
            height: "30px",
            transform: "translateY(15px)",
            background: "#1E6BB1",
          },
          "50%": {
            height: "5px",
            transform: "translateY(0px)",
            background: "#01D7E2",
          },
          "100%": {
            height: "5px",
            transform: "translateY(0px)",
            background: "#1E6BB1",
          },
        },
      }}
    >
      <div class="wrapper">
        <div id="preloader_1">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </Box>
  );
};

export default SoundWaveLoader;
