import React, { useState } from "react";
import { Box, Grid, Text, Heading, Flex, Button } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import theme from "../../../gatsby-plugin-theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";

import AppleIcon from "../../../assets/img/_canali/_footer/apple_f.inline.svg";
import IheartIcon from "../../../assets/img/_canali/iheart_s.inline.svg";
import SpotifyIcon from "../../../assets/img/_canali/_footer/spotify_f.inline.svg";
import SpreakerIcon from "../../../assets/img/_canali/_footer/spreaker_f.inline.svg";

const Expert = ({ data }) => {
  const [readMore, setReadMore] = useState(false);
  const primary = getColor(theme, "primary");
  const secondary = getColor(theme, "secondary");
  const { image, name, description, podcast } = data;

  return (
    <Box
      sx={{
        pr: [0, 4],
        pl: [3, 3,4],
        py: [4,4, 7],
        height: "100%",
        position: "relative",
        backgroundColor: "dark",
      }}
    >
      <Box sx={{}}>
        <Heading
          as="h2"
          sx={{
            display: ["none", "none",   "block"],
            width: ["100%", "75%", "75%"],
            mt: [0],
            mb: [4, 5],
            minHeight: ["auto", "auto", "160px"],
            fontWeight: "500",
            lineHeight: 1,
            fontSize: [5, 7, 11],
            textTransform: "uppercase",
          }}
        >
          {name}
        </Heading>
        <Grid columns={["auto", "auto","297px 1fr"]} gap={[0, 0, 3]}>
          <Box
            sx={{
              ".gatsby-image-wrapper": {
                height: "100%",
                borderRadius: "none",
                height: "297px",
                width: "297px",
              },
            }}
          >
            {image && image.gatsbyImageData && (
              <GatsbyImage image={image.gatsbyImageData} alt={name} />
            )}
          </Box>
          <Heading
            as="h2"
            sx={{
              display: ["block", "block",   "none"],
              width: ["100%", "100%", "75%"],
              mt: [4],
              mb: [4, 5],
              minHeight: ["auto", "auto",  "160px"],
              fontWeight: "400",
              lineHeight: 1,
              fontSize: [5, 7,  11],
              textTransform: "uppercase",
            }}
          >
            {name}
          </Heading>
          <Flex
            sx={{ flexDirection: "column", justifyContent: "space-between", position:"relative" }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "max-height 200ms ease-in-out",
                maxHeight: [
                  readMore ? "600px" : "0",
                  readMore ? "600px" : "0",
                  "600px",
                ],
                overflow: "hidden",
                position:"relative"
              }}
            >
              <Box sx={{position:"relative"}}>
                <Text
                  dangerouslySetInnerHTML={{ __html: description }}
                  as="div"
                  sx={{
                    p: {
                      lineHeight: 1.2,
                      color: "primary",
                      mt: [0],
                      mb: [0],
                      fontWeight: "500",
                      fontSize: [2],
                    },
                  }}
                />
              </Box>
              <Box className={"experts-podcast-section"} sx={{position:"relative"}}>
                {podcast && podcast.podcastNumber && (
                  <>
                    <Box sx={{ pt: [3, 3] }}>
                      <Box>
                        <Text
                          sx={{
                            textTransform: "uppercase",
                            fontWeight: "600",
                            fontSize: [2],
                          }}
                        >
                          ASCOLTA L'EPISODIO {podcast.podcastNumber}
                        </Text>
                      </Box>
                      <Box sx={{ pb: [2] }}>
                        <Text
                          sx={{
                            lineHeight: "1",
                            textTransform: "uppercase",
                            fontSize: [2],
                          }}
                        >
                          {podcast.title}
                        </Text>
                      </Box>
                    </Box>
                    <Flex sx={{ flexWrap: "wrap", zIndex:99999, position:"relative" }}>
                      <PodcastLink link={podcast.spotify}>
                        <SpotifyIcon />
                      </PodcastLink>
                      <PodcastLink link={podcast.applePodcast}>
                        <AppleIcon />
                      </PodcastLink>
                      <PodcastLink link={podcast.iheart}>
                        <IheartIcon />
                      </PodcastLink>
                      <PodcastLink link={podcast.spreaker}>
                        <SpreakerIcon />
                      </PodcastLink>
                    </Flex>
                  </>
                )}
              </Box>
            </Box>
            <Box
              sx={{ display: ["block", "block", "none"], py: [readMore && 3] }}
            >
              <Button
                sx={{
                  textAlign: "center",
                  fontSize: [2, 3],
                  fontWeight: "600",
                  borderRadius: "unset",
                  p: [1],
                  position: "relative",
                  background: `linear-gradient(to right, #01D7E2, #1E6BB1)`,
                }}
                onClick={() => setReadMore(!readMore)}
              >
                {!readMore ? "Leggi tutto" : "Nascondi"}
              </Button>
            </Box>
          </Flex>
        </Grid>
      </Box>
    </Box>
  );
};

const PodcastLink = ({ children, link, text }) => {
  return (
    <Box
      sx={{
        position:"relative",
        zIndex:99999,
        a: {
          zIndex:99999,
          mr: [2],
          color: "primary",
          fontSize: [2],
          textDecoration: "underline",
          fontWeight: "bold",
          width: "90%",
          textAlign: "left",
        },
      }}
    >
      <a href={link} target="_blank">
        {children}
      </a>
    </Box>
  );
};

export default Expert;
