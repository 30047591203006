import React, { useState } from "react";
import { Box, Grid, Text, Heading, Flex, Button } from "theme-ui";
import { GatsbyImage } from "gatsby-plugin-image";
import theme from "../../../gatsby-plugin-theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";
import { useBreakpointIndex } from "@theme-ui/match-media";

const Guest = ({ data }) => {
  const [readMore, setReadMore] = useState(false);
  const primary = getColor(theme, "primary");
  const secondary = getColor(theme, "secondary");
  const { image, name, description } = data;
  const mediaIndex = useBreakpointIndex();

  return (
    <Box
      sx={{
        px: [3, 4],
        py: [4, 7],
        height: "100%",
        position: "relative",
        backgroundColor: "primary",
      }}
    >
      <Box sx={{}}>
        <Grid columns={["auto", "auto", "auto"]} gap={[0, 0, 7]}>
          <Heading
            as="h2"
            sx={{
              display: ["block", "block", "none", "none"],
              width: ["100%", "100%", "75%"],
              mt: [4],
              mb: [4, 5],
              minHeight: ["auto", "auto", "160px"],
              fontWeight: "400",
              lineHeight: 1,
              fontSize: [5, 7, 8, 9, 11],
              textTransform: "uppercase",
              color: "dark",
            }}
          >
            {name}
          </Heading>
          <Flex sx={{ flexDirection: "column", justifyContent: "center" }}>
            <Heading
              as="h2"
              sx={{
                display: ["none", "none", "block", "block"],
                width: ["100%", "100%", "75%"],
                mt: [0],
                mb: [4, 5],
                fontWeight: "500",
                lineHeight: 1,
                fontSize: [5, 7, 11],
                textTransform: "uppercase",
                color: "dark",
              }}
            >
              {name}
            </Heading>
            <Box
              sx={{
                mb: [4, 5],
                display:"grid",
                gridTemplateColumns:["repeat(auto-fill, minmax(280px, 1fr))"],
                gap:[3],
                ".gatsby-image-wrapper": {
                  height: "auto",
                  borderRadius: "none",
                  width: "100%",
                  maxHeight: "297px",
                  maxWidth: "297px",
                },
              }}
            >
              {image && (
                <GatsbyImage
                  image={mediaIndex > 2 ? image.desktop : image.mobile}
                  alt={name}
                />
              )}
              <Box
                sx={{
                  transition: "max-height 200ms ease-in-out",
                  maxHeight: [
                    readMore ? "600px" : "0",
                    readMore ? "600px" : "0",
                    "600px",
                  ],
                  overflow: "hidden",
                }}
              >
                <Text
                  dangerouslySetInnerHTML={{ __html: description }}
                  as="div"
                  sx={{
                    p: {
                      maxWidth: "800px",
                      lineHeight: "1.2",
                      color: "dark",
                      mt: [0],
                      mb: [0],
                      fontWeight: "500",
                      fontSize: [2],
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{ display: ["block", "block", "none"], py: [readMore && 3] }}
            >
              <Button
                sx={{
                  textAlign: "center",
                  fontSize: [2, 3],
                  fontWeight: "600",
                  borderRadius: "unset",
                  p: [1],
                  position: "relative",
                  background: `linear-gradient(to right, #01D7E2, #1E6BB1)`,
                }}
                onClick={() => setReadMore(!readMore)}
              >
                {!readMore ? "Leggi tutto" : "Nascondi"}
              </Button>
            </Box>
          </Flex>
        </Grid>
      </Box>
    </Box>
  );
};

export default Guest;
