import React, { useEffect, useContext } from "react";
import { Box, Grid, Flex, Text, Heading, Image } from "theme-ui";
import CurrentPodcastContext from "../../../hooks/currentPodcastContext";
import PlayCircleIcon from "../../../assets/img/_bottoni/play_circle.inline.svg";
import PauseCircleIcon from "../../../assets/img/_bottoni/pause_circle.inline.svg";
import PodcastThumb from "../../../assets/img/_teaser/anteprima.png";

import AppleIcon from "../../../assets/img/_canali/apple.inline.svg";
import IheartIcon from "../../../assets/img/_canali/iheart.inline.svg";
import SpotifyIcon from "../../../assets/img/_canali/spotify.inline.svg";
import SpreakerIcon from "../../../assets/img/_canali/spreaker.inline.svg";

import AppleMobileIcon from "../../../assets/img/_canali/_footer/apple_f.inline.svg";
import IheartMobileIcon from "../../../assets/img/_canali/_footer/iheart_f.inline.svg";
import SpotifyMobileIcon from "../../../assets/img/_canali/_footer/spotify_f.inline.svg";
import SpreakerMobileIcon from "../../../assets/img/_canali/_footer/spreaker_f.inline.svg";

import { useBreakpointIndex } from "@theme-ui/match-media";

import loadable from "@loadable/component";

const Wave = loadable(() => import("./wave"), { ssr: false });

const PodcastPlayer = ({}) => {
  const mediaIndex = useBreakpointIndex();
  const { currentPodcast, setCurrentPodcast } = useContext(
    CurrentPodcastContext
  );

  const handleSetCurrentPodcast = () => {
    setCurrentPodcast({
      ...currentPodcast,
      play: !currentPodcast.play,
    });
    if (
      typeof window !== "undefined" &&
      window.dataLayer !== undefined
    ) {
      window.dataLayer.push({
        event: !currentPodcast.play === true ? "clic_play" : "clic_pausa",
      });
    }
  };

  return (
    currentPodcast && (
      <Box>
        <>
          <Grid
            columns={["90px auto", "150px auto", "175px auto"]}
            sx={{
              columnGap: [3, 4],
              rowGap: [0],
            }}
          >
            <Flex
              sx={{
                alignContent: "start",
                alignItems: "center",
                gridRow: "1/2",
              }}
            >
              <Image sx={{ maxHeight: "174px" }} src={PodcastThumb} alt="Podcast thumbnail" />{" "}
            </Flex>
            <Flex
              sx={{
                gridRow: "1/1",
                maxHeight: "90px",
                justifyContent: "space-between",
                minHeight: "90px",
                alignItems: "flex-start",
              }}
            >
              <Box sx={{ height: "40%" }}>
                <Box sx={{ minHeight: "20px" }}>
                  <Text variant="kicker">
                    {currentPodcast &&
                      currentPodcast.kicker &&
                      currentPodcast.kicker}
                  </Text>
                </Box>
                <Text
                  as="p"
                  variant="h3"
                  sx={{
                    my: [2],
                    color: "light",
                    fontSize: [2, 5],
                    maxWidth: "450px",
                    wordBreak: "break-word",
                    maxHeight: "54px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {currentPodcast.title}
                </Text>
              </Box>
              {mediaIndex > 1 && (
                <Box
                  sx={{
                    cursor: "pointer",
                    svg: {
                      width: ["35px", "auto"],
                      height: ["35px", "auto"],
                    },
                  }}
                  onClick={() => handleSetCurrentPodcast()}
                >
                  {currentPodcast.play ? (
                    <PauseCircleIcon />
                  ) : (
                    <PlayCircleIcon />
                  )}
                </Box>
              )}
            </Flex>
            {currentPodcast.file && currentPodcast.file.url && (
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  gridRow: "2/2",
                  gridColumn: ["1/3","1/3", "2/3"],
                  height: ["66px","66px", "auto"],
                }}
              >
                <Box sx={{ position: "relative", width: "100%" }}>
                  <Box
                    sx={{ position: "absolute", width: "100%", bottom: [0] }}
                  >
                    <Wave
                      playingStatus={currentPodcast.play}
                      audio={currentPodcast.file.url}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    ml: [3],
                    display: ["flex", "flex", "none", "none"],
                    alignContent: "center",
                    alignItems: "end",
                    justifyContent: "center",
                    cursor: "pointer",
                    svg: {
                      width: ["35px","35px", "auto"],
                      height: ["35px","35px", "auto"],
                    },
                  }}
                  onClick={() => handleSetCurrentPodcast()}
                >
                  {currentPodcast.play ? (
                    <PauseCircleIcon />
                  ) : (
                    <PlayCircleIcon />
                  )}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid
            columns={["auto", "auto", "auto",  "175px minmax(600px,1fr) 1fr"]}
            gap={[0]}
            sx={{ mt: [2,2, 7], justifyContent: ["flex-start","flex-start", "flex-end"] }}
          >
            <Box sx={{ display: ["none","none", "block"], minWidth:["0","0","175px"] }}></Box>
            <Text
              as="div"
              variant="p"
              dangerouslySetInnerHTML={{ __html: currentPodcast.description }}
              sx={{
                p: {
                  my: [0],
                  lineHeight: 1.167,
                  fontSize: [2, 5],
                },
              }}
            />
            <Box sx={{ pl: [0,'', 8], pt:[0,0,4,0], gridRow: ["1/2","1/2", "unset"] }}>
              <Box
                sx={{
                  display: ["none", "none", "block", "block"],
                  textAlign: "right",
                }}
              >
                <Text
                  as="div"
                  sx={{
                    p: {
                      my: [0],
                      fontSize: [5],
                      color: "primary",
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: currentPodcast.linkDescriptions,
                  }}
                />
              </Box>
              <Flex
                sx={{ pt: [0,0, 6], justifyContent: ["flex-start","flex-start", "flex-end"] }}
              >
                <Grid
                  columns={["auto auto auto auto","auto auto auto auto", "repeat(auto-fill, 160px)"]}
                  sx={{
                    justifyContent: ["flex-start","flex-start", "flex-end"],
                    maxWidth: ["unset","unset", "350px"],
                  }}
                >
                  <PodcastButton link={currentPodcast.spotify}>
                    {mediaIndex > 1 ? <SpotifyIcon /> : <SpotifyMobileIcon />}
                  </PodcastButton>
                  <PodcastButton link={currentPodcast.applePodcast}>
                    {mediaIndex > 1 ? <AppleIcon /> : <AppleMobileIcon />}
                  </PodcastButton>
                  <PodcastButton link={currentPodcast.iheart}>
                    {mediaIndex > 1 ? <IheartIcon /> : <IheartMobileIcon />}
                  </PodcastButton>
                  <PodcastButton link={currentPodcast.spreaker}>
                    {mediaIndex > 1 ? <SpreakerIcon /> : <SpreakerMobileIcon />}
                  </PodcastButton>
                </Grid>
              </Flex>
            </Box>
          </Grid>
        </>
      </Box>
    )
  );
};

const PodcastButton = ({ children, link }) => {
  return (
    <Box
      sx={{
        a: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "53px",
          width: ["auto","auto", "160px"],
          border: "3px solid",
          borderColor: ["transparent", "transparent", "light"],
          svg: {
            maxHeight: ["unset","unset", "28px"],
            height:"28px",
            maxWidth: ["90%","90%", "60%"],
            width: "auto",
          },
        },
      }}
    >
      <a target="_blank" href={link}>
        {children}
      </a>
    </Box>
  );
};

export default PodcastPlayer;
