import React, { useEffect, useState, useRef } from "react";
import DoubleArrow from "../../assets/img/_frecce/double-arrow.inline.svg"
import { Box } from "theme-ui";

const CustomCursor = ({elemContainer,
  elementPodcastDOM}) => {
  const delay = 18;

  const dot = useRef(null);

  const cursorVisible = useRef(true);
  const cursorEnlarged = useRef(false);

  const endX = useRef(window.innerWidth / 2);
  const endY = useRef(window.innerHeight / 2);
  const _x = useRef(0);
  const _y = useRef(0);

  const requestRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", mouseOverEvent);
    document.addEventListener("mouseup", mouseOutEvent);
    document.addEventListener("mousemove", mouseMoveEvent);
    document.addEventListener("mouseenter", mouseEnterEvent);
    document.addEventListener("mouseleave", mouseLeaveEvent);

    return () => {
      document.removeEventListener("mousedown", mouseOverEvent);
      document.removeEventListener("mouseup", mouseOutEvent);
      document.removeEventListener("mousemove", mouseMoveEvent);
      document.removeEventListener("mouseenter", mouseEnterEvent);
      document.removeEventListener("mouseleave", mouseLeaveEvent);

      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const toggleCursorVisibility = () => {};

  const toggleCursorSize = () => {
    if (cursorEnlarged.current) {
      dot.current.style.transform = "translate(-50%, -50%) scale(0.75)";
    } else {
      dot.current.style.transform = "translate(-50%, -50%) scale(1)";
    }
  };

  const mouseOverEvent = () => {
    cursorEnlarged.current = true;
    toggleCursorSize();
  };

  const mouseOutEvent = () => {
    cursorEnlarged.current = false;
    toggleCursorSize();
  };

  const mouseEnterEvent = () => {
    cursorVisible.current = true;
    toggleCursorVisibility();
  };

  const mouseLeaveEvent = () => {
    cursorVisible.current = false;
    toggleCursorVisibility();
  };

  const mouseMoveEvent = (e) => {
    cursorVisible.current = true;
    toggleCursorVisibility();

    endX.current = e.pageX;
    endY.current = e.pageY;

    dot.current.style.top = endY.current + "px";
    dot.current.style.left = endX.current + "px";
  };

  useEffect(() => {
    console.log(".elemContainer")
    var container = document.querySelector(elemContainer);
    var elementPodcast = document.querySelectorAll(elementPodcastDOM);

    dot.current.style.opacity = 0;

    container.onmouseover = function () {
      let mouseOverElement = false;
      if (elementPodcast.length > 0)
        elementPodcast.forEach((element) => {
          if (element.matches(":hover")) mouseOverElement = true;
        });

      if (mouseOverElement) dot.current.style.opacity = 0;
      else dot.current.style.opacity = 1;
    };

    container.onmouseout = function () {
      dot.current.style.opacity = 0;
    };
  }, []);

  return (
    <>
      <Box
        ref={dot}
        className="cursor-dot"
        sx={{
          pointerEvents: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "50%",
          opacity: 1,
          transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
          width: "100px",
          height: "100px",
          backgroundColor: "#dc5656",
          zIndex: "9999",
          background: `linear-gradient(to right, #01D7E2, #1E6BB1)`,
        }}
      >
        <Box
          sx={{
            fontWeight: "600",
            fontSize: [1],
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
            svg:{

            }
          }}
        >
          Drag
          <DoubleArrow />
        </Box>
      </Box>
    </>
  );
};

export default CustomCursor;
