import React from "react";
import { Box, Container, Grid, Heading, Text, Image } from "theme-ui";
import FluidBackground from "../../assets/img/_cta/sfondo-CTA_optm.jpg";

import AppleIcon from "../../assets/img/_canali/apple.inline.svg";
import IheartIcon from "../../assets/img/_canali/iheart.inline.svg";
import SpotifyIcon from "../../assets/img/_canali/spotify.inline.svg";
import SpreakerIcon from "../../assets/img/_canali/spreaker.inline.svg";

const JoinChannel = ({ block }) => {
  const { background, kicker, title, content } = block;

  return (
    <Box sx={{ backgroundColor: "primary", position: "relative" }}>
      <Container sx={{ py: [6, 6, 12, 13], position: "relative", zIndex: 1 }}>
        <Grid columns={["1fr", "1fr", "1fr 1fr"]}>
          <Box>
            {kicker && (
              <Heading as="h2" variant="kicker" sx={{ color: "light" }}>
                {kicker}
              </Heading>
            )}
            <Text
              as="div"
              dangerouslySetInnerHTML={{ __html: title }}
              sx={{
                p: {
                  my: [0],
                  mt: [3, 3, "24px"],
                  fontSize: [4, 6, 6, "42px"],
                  lineHeight: "1.071",
                  fontWeight: "500",
                },
              }}
            />
          </Box>
          <Box sx={{ pt: [6], textAlign: "right" }}>
            <Grid
              columns={["1fr 1fr"]}
              sx={{ justifyContent: "end" }}
              gap={[0]}
            >
              {content.map((item, index) => (
                <PodcastButton key={item.id} link={item.url}>
                  {item.channelType === "SPOTIFY" && <SpotifyIcon />}
                  {item.channelType === "APPLE PODCAST" && <AppleIcon />}
                  {item.channelType === "SPREAKER" && <SpreakerIcon />}
                  {item.channelType === "IHEART" && <IheartIcon />}
                </PodcastButton>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Container>
      <Image
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        src={FluidBackground}
        alt="Fluid background"
      />
    </Box>
  );
};

const PodcastButton = ({ children, link }) => {
  return (
    <Box
      sx={{
        a: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: ["70px", "90px"],
          py: [3],
          px: [3, 5],
          mb: ["-3px"],
          mr: ["-3px"],
          border: "3px solid",
          borderColor: "light",
          svg: {
            maxHeight: ["35px", "50px"],
            height: ["35px", "50px"],
            width: "auto",
          },
        },
      }}
    >
      <a target="_blank" href={link}>
        {children}
      </a>
    </Box>
  );
};

export default JoinChannel;
