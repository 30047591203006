import React, { useEffect, useRef, useState } from "react";
import { Box, Text, Container, Flex, Grid } from "@theme-ui/components";
import Img from "gatsby-image";

import { Mousewheel, Pagination, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "/node_modules/swiper/swiper.min.css";
import Guest from "./Guest";

import theme from "../../gatsby-plugin-theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";
import { useBreakpointIndex } from "@theme-ui/match-media";
import CustomCursor from "./CustomCursor";

const GuestCollection = ({ block }) => {
  const container = useRef();
  const mediaIndex = useBreakpointIndex();
  const primary = getColor(theme, "primary");
  const secondary = getColor(theme, "secondary");
  const { guests, title } = block;
  

  guests.sort((a,b)=> parseInt(b.podcast.podcastNumber) - parseInt(a.podcast.podcastNumber))

  return (
    <>
      {mediaIndex > 1 && (
        <CustomCursor
          elemContainer={".container_guests"}
          elementPodcastDOM={".guest-podcast-section"}
        />
      )}

      <Grid
        className="container_guests"
        ref={container}
        columns={["1fr", "1fr", "165px auto"]}
        gap={[0]}
        sx={{
          mt: ["3px"],
          mb: ["3px"],
          overscrollBehaviorX: "contain",
          position: "relative",
          ".mySwiper .swiper-wrapper .swiper-slide:first-child": {},
          ".mySwiper .swiper-wrapper .swiper-slide:last-child": {},
          ".mySwiper .swiper-slide": {
            maxWidth: [315, 330, "670px"],
            height: "auto",
          },
          "&:before": {
            content: "''",
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            zIndex: -1,
            mt: "-3px",
            mb: "-3px",
            mr: "0px",
            ml: "0px",
            borderRadius: "inherit",
            background: `linear-gradient(to bottom, ${primary}, ${secondary})`,
          },
        }}
      >
        <Box
          sx={{
            position: ["relative", "relative", "relative"],
            minWidth: "162px",
            height: "100%",
            backgroundColor: "dark",
            px: [3, 4],
            py: [4, 7],
            zIndex: "1",
            background: `linear-gradient(to bottom, ${primary}, ${secondary})`,
            "&:before": {
              content: "''",
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              zIndex: -1,
              mt: "0px",
              mb: ["3px", "3px", "0px"],
              mr: ["0px", "0px", "3px"],
              ml: "0px",
              borderRadius: "inherit",
              backgroundColor: "dark",
            },
          }}
        >
          <Text
            as="h2"
            sx={{
              width: "80%",
              mt: [0],
              mb: [0, 0, 2],
              fontWeight: "500",
              lineHeight: 1,
              fontSize: [7, 8, 9, 11],
              textTransform: "uppercase",
              transform: ["unset", "unset", "rotate(270deg)"],
              position: ["relative", "relative", "absolute"],
              left: 0,
              bottom: [0, 0, 8],
              color: "primary",
            }}
          >
            {title}
          </Text>
        </Box>
        <Box
          sx={{
            position: "relative",
            zIndex: "0",
            overscrollBehaviorX: "contain",
          }}
        >
          <Swiper
            resistanceRatio={0}
            modules={[Pagination, Mousewheel, Keyboard]}
            mousewheel={{
              forceToAxis: true,
              sensitivity: 1,
              releaseOnEdges: true,
            }}
            grabCursor={false}
            autoHeight={false}
            preventClicksPropagation={true}
            preventClicks={true}
            spaceBetween={mediaIndex > 1 ? 3 : -2}
            className="mySwiper"
            slidesPerView={"auto"}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            pagination={{
              dynamicBullets: true,
              clickable: false,
            }}
          >
            {guests &&
              guests.length > 0 &&
              guests.map((guest, index) => (
                <SwiperSlide key={guest.id}>
                  <Box
                    sx={{
                      height: "100%",
                      "&:before": {
                        content: "''",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                        zIndex: -1,
                        mt: "0px",
                        mb: "0px",
                        mr: ["0px", "0px", "-3px"],
                        ml: "0px",
                        borderRadius: "inherit",
                        background: `linear-gradient(to bottom, ${primary}, ${secondary})`,
                      },
                    }}
                  >
                    <Guest data={guest} />
                  </Box>
                </SwiperSlide>
              ))}
          </Swiper>
        </Box>
      </Grid>
    </>
  );
};

export default GuestCollection;
