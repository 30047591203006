import React from "react";
import { Box, Flex, Heading, Text, Container, Grid, Textarea } from "theme-ui";
import theme from "../../gatsby-plugin-theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";

import AppleIcon from "../../assets/img/_canali/_footer/apple_f.inline.svg";
import IheartIcon from "../../assets/img/_canali/iheart_s.inline.svg";
import SpotifyIcon from "../../assets/img/_canali/_footer/spotify_f.inline.svg";
import SpreakerIcon from "../../assets/img/_canali/_footer/spreaker_f.inline.svg";

const Footer = ({ data }) => {
  const {
    title,
    description,
    copyright,
    privacy,
    cookie,
    legalInfo,
    spotifyUrl,
    appleUrl,
    spreakerUrl,
    iheartUrl,
  } = data;
  const primary = getColor(theme, "primary");
  const secondary = getColor(theme, "secondary");

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "dark",
        pt: [7,9],
        color: "light",
        "&:before": {
          content: "''",
          position: "absolute",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          zIndex: -1,
          mt: "-3px",
          mb: "0px",
          mr: "0px",
          ml: "0px",
          borderRadius: "inherit",
          background: `linear-gradient(to right, ${primary}, ${secondary})`,
        },
      }}
    >
      <Container sx={{ py: [0, 0] }}>
        <Flex sx={{ alignItems: "center", flexDirection: "column" }}>
          <Heading
            as="h2"
            sx={{
              fontSize: ["42px"],
              lineHeight: "1.071",
              color: "light",
              fontWeight: "500",
              textTransform:"uppercase"
            }}
          >
            {title}
          </Heading>
          <Text
            as="div"
            sx={{
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: [3],
              fontWeight: "600",
              p: {
                mb: [4],
              },
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <Flex>
            {spotifyUrl && (
              <Box sx={{ mr: [3], a: {} }}>
                <a href={spotifyUrl}>
                  <SpotifyIcon />
                </a>
              </Box>
            )}
            {appleUrl && (
              <Box sx={{ mr: [3] }}>
                <a href={appleUrl}>
                  <AppleIcon />
                </a>
              </Box>
            )}
            {spreakerUrl && (
              <Box sx={{ mr: [3] }}>
                <a href={spreakerUrl}>
                  <IheartIcon />
                </a>
              </Box>
            )}
            {iheartUrl && (
              <Box sx={{ mr: [3] }}>
                <a href={iheartUrl}>
                  <SpreakerIcon />
                </a>
              </Box>
            )}
          </Flex>
          <Text sx={{ py: [4] }}>{copyright}</Text>
          <Text sx={{ pt: [6], pb: [4], fontSize: [1], textAlign:"center", a:{
            textDecoration:"none",
            "&:hover":{
              color:"primary"
            }
          } }}>
            {legalInfo}
            <br/><a href={privacy} target="_blank">Privacy Policy</a>
            {" | "}
            <a href={cookie} target="_blank">Cookie Policy</a>
            <br/>
            <br/>
            {/* <a href="#" class="iubenda-cs-preferences-link">Aggiorna le impostazioni della privacy</a> */}

          </Text>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
