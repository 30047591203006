import React from "react";
import { Box } from "theme-ui";
import Nav from "./nav";
import MobileNav from "./mobileNav";

const Header = ({company}) => {
  return (
    <Box sx={{ maxWidth: "1920px",}}>
      <Box sx={{ display: ["none", "none", "block", "block"] }}>
        <Nav />
      </Box>
      <Box sx={{ display: ["block", "block", "none", "none"] }}>
        <MobileNav company={company} />
      </Box>
    </Box>
  );
};

export default Header;
