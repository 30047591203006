import React from "react";
import { graphql } from "gatsby";
import {
  Container,
  Heading,
  Box,
  Text,
  Image,
  Grid,
} from "@theme-ui/components";
// import Layout from "../components/layout";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { useFavicon } from "../hooks/useFavicon";
import Header from "../components/Header";
import PodcastCollection from "../components/PodcastCollection";
import JoinChannel from "../components/JoinChannel";
import GuestCollection from "../components/GuestsCollection";
import ExpertCollection from "../components/ExpertsCollection";
import SpeakerSection from "../components/SpeakerSection";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import { GatsbyImage } from "gatsby-plugin-image";

import loadable from "@loadable/component";
import { useBreakpointIndex } from "@theme-ui/match-media";
import Guest from "../components/GuestsCollection/Guest";

const CustomCursor = loadable(
  () => import("../components/GuestsCollection/CustomCursor"),
  { ssr: false }
);

const Home = ({
  data: { page, site, company, contactSection, lastPodcast },
}) => {
  const favicon = useFavicon().site.faviconMetaTags;
  const mediaIndex = useBreakpointIndex();

  const i18nPaths = site.locales.map((locale) => {
    return {
      locale: locale,
      value: "/",
    };
  });

  return (
    // <Layout >
    <>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
        <title>Monoliti</title>
        <link rel="canonical" href="htts://monoliti.telmotor.it" />
        <meta name="description" content={page.description} />
        <meta content="htts://monoliti.telmotor.it" property="og:url" />
      </HelmetDatoCms>
      <Header company={company} />
      <Box sx={{ position: "relative", pt: [8, 8, 0, 0] }}>
        <Container sx={{ position: "relative", zIndex: 1 }}>
          <Box sx={{ textAlign: "center" }}>
            <Heading
              dangerouslySetInnerHTML={{ __html: page.title }}
              as="div"
              sx={{
                h1: {
                  my: [3],
                  lineHeight: 1,
                  fontSize: [7, 8, 9, 10],
                  textTransform: "uppercase",
                  em: {
                    fontStyle: "normal",
                    color: "light",
                    "-webkit-text-fill-color": "transparent",
                    "-webkit-text-stroke": "1px",
                  },
                },
              }}
            />
            <Text
              dangerouslySetInnerHTML={{ __html: page.subtitle }}
              as="div"
              sx={{
                p: {
                  my: [4],
                  fontWeight: "500",
                  fontSize: [3, 5],
                },
              }}
            />
          </Box>
        </Container>
        <Box
          sx={{
            zIndex: 1,
            position: "absolute",
            top: ["75%", "65%", "60%", "55%"],
            left: "50%",
            transform: [
              "translate(-50%,-75%)",
              "translate(-50%,-65%)",
              "translate(-50%,-60%)",
              "translate(-50%,-55%)",
            ],
            zIndex: 1,
            width: "100%",
            px: [3, 4],
          }}
        >
          <Box
            sx={{
              m: ["0 auto", "0 auto"],
              width: ["100%", "450px", "450px", "545px"],
              iframe: {
                height: ["352px", "352px", "352px"],
              },
            }}
            dangerouslySetInnerHTML={{ __html: page.embedCurrentEpisode }}
          />
          {lastPodcast && lastPodcast.nodes.length > 0 && (
             <Box
                sx={{
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Heading
                  as="h2"
                  variant="h2"
                  sx={{
                    fontSize: [3, 5, 6],
                    fontWeight: "500",
                    lineHeight: "1.1!important",
                    maxWidth: ["100%", "50%"],
                  }}
                >
                  {console.log("lastPodcast", lastPodcast)}
                  {lastPodcast.nodes[0].title}
                </Heading>
              </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "relative",
            mt: [0, -6, -7, "-5%"],
            zIndex: -1,
            minHeight: "450px",
          }}
        >
          <Box
            sx={{
              minHeight: "450px",
              ".gatsby-image-wrapper": {
                minHeight: "450px",
                height: "100%",
                overflowX: "hidden",
                overflowY: "hidden",
                borderRadius: "none",
                img: {
                  transform: [
                    "scale(1.35)",
                    "scale(1.35)",
                    "scale(1.25)",
                    "scale(1)",
                  ],
                },
              },
            }}
          >
            {page.heroImage && page.heroImage.gatsbyImageData && (
              <GatsbyImage
                image={page.heroImage.gatsbyImageData}
                alt={page.heroImage.gatsbyImageData}
              />
            )}
          </Box>
        </Box>
        <Container
          sx={{ position: "relative", zIndex: 2, mt: [0, -6, -8, "-15%"] }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Text
              dangerouslySetInnerHTML={{ __html: page.subsubtitle }}
              as="div"
              sx={{
                p: {
                  fontWeight: "500",
                  fontSize: [4, 6, 8, "42px"],
                  lineHeight: "1",
                },
              }}
            />
          </Box>
        </Container>
      </Box>

      {page.content.map((block, index) => (
        <>
          {block.model.apiKey === "podcast_collection" && (
            <Box as="section" id={block.model.apiKey} key={block.id}>
              <PodcastCollection block={block} />
            </Box>
          )}
          {block.model.apiKey === "guest_collection" && (
            <Box as="section" id={block.model.apiKey} key={block.id}>
              <GuestCollection block={block} />
            </Box>
          )}
          {block.model.apiKey === "join_channel" && (
            <Box as="section" id={block.id} key={block.id}>
              <JoinChannel block={block} />
            </Box>
          )}
          {block.model.apiKey === "speakers_and_experts_section" && (
            <Box as="section" id={block.id} key={block.id}>
              <Grid columns={["1fr", "1fr", "1fr", ".45fr .55fr"]} gap={[0]}>
                {block.content.map((blockContentItem) =>
                  blockContentItem.model.apiKey === "expert_collection" ? (
                    <ExpertCollection block={blockContentItem} />
                  ) : blockContentItem.model.apiKey === "speaker_section" ? (
                    <SpeakerSection block={blockContentItem} />
                  ) : (
                    ""
                  )
                )}
              </Grid>
            </Box>
          )}
          {block.model.apiKey === "speaker_section" && (
            <Box as="section" id={block.id} key={block.id}></Box>
          )}
          {block.model.apiKey === "contact_form" && (
            <Box as="section" id={block.model.apiKey} key={block.id}>
              <ContactForm block={block} />
            </Box>
          )}
        </>
      ))}
      {company && <Footer data={company} />}
      {/* </Layout> */}
    </>
  );
};

export default Home;

export const query = graphql`
  query HomeQuery {
    page: datoCmsHomePage {
      id
      heroImage {
        gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
      }
      title
      embedCurrentEpisode
      subtitle
      subsubtitle
      content {
        ... on DatoCmsSpeakersAndExpertsSection {
          id
          content {
            ... on DatoCmsSpeakerSection {
              id
              title
              speaker {
                name
                description
                image {
                  desktop: gatsbyImageData(
                    width: 300
                    placeholder: BLURRED
                    forceBlurhash: false
                  )
                  mobile: gatsbyImageData(
                    width: 350
                    placeholder: BLURRED
                    forceBlurhash: false
                  )
                }
              }
              model {
                apiKey
              }
            }
            ... on DatoCmsExpertCollection {
              id
              title
              experts {
                image {
                  gatsbyImageData(
                    width: 297
                    placeholder: BLURRED
                    forceBlurhash: false
                  )
                }
                name
                description
                podcast {
                  kicker
                  title
                  podcastNumber
                  spotify
                  applePodcast
                  iheart
                  spreaker
                }
              }
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPodcastCollection {
          id
          podcasts {
            id
            kicker
            title
            file {
              url
            }
            description
            podcastNumber
            linkDescriptions
            spotify
            applePodcast
            iheart
            spreaker
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsGuestCollection {
          id
          title
          guests {
            image {
              gatsbyImageData(
                width: 297
                placeholder: BLURRED
                forceBlurhash: false
              )
            }
            name
            description
            podcast {
              kicker
              title
              podcastNumber
              spotify
              applePodcast
              iheart
              spreaker
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsJoinChannel {
          id
          kicker
          title
          background {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          content {
            ... on DatoCmsPodcastChannel {
              title
              url
              channelType
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSpeakerSection {
          id
          title
          speaker {
            name
            description
            image {
              desktop: gatsbyImageData(
                width: 300
                placeholder: BLURRED
                forceBlurhash: false
              )
              mobile: gatsbyImageData(
                width: 350
                placeholder: BLURRED
                forceBlurhash: false
              )
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          privacyPolicy
          newsletterAgreement
          model {
            apiKey
          }
        }
      }
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    site: datoCmsSite {
      locales
    }
    lastPodcast: allDatoCmsPodcast(limit: 1, sort: { fields: position }) {
      nodes {
        id
        title
      }
    }
    company: datoCmsCompany {
      title
      description
      copyright
      privacy
      cookie
      legalInfo
      appleUrl
      spotifyUrl
      spreakerUrl
      iheartUrl
    }
  }
`;
