import React from "react";
import { Box } from "theme-ui";
import PodcastListItem from "./ListItem";
import theme from "../../../gatsby-plugin-theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";

const PodcastList = ({ podcasts }) => {
  const orderedPodcasts = [...podcasts]
  const primary = getColor(theme, "primary");
  const secondary = getColor(theme, "secondary");

  return (
    <Box
      sx={{
        display: "block",
        "&::-webkit-scrollbar": {
          width: "10px",
          backgroundColor: "#F5F5F5",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#0ae",
          backgroundImage: `linear-gradient(to right, ${secondary},${primary})`,
        },
        "&::-webkit-scrollbar-track": {
          WebkitBoxShadow: "inset 0 0 6px rgb(0 0 0 / 30%)",
          backgroundColor: "secondary",
        },
      }}
    >
      {orderedPodcasts.map((podcast) => (
        <Box key={podcast.id}>
          <PodcastListItem podcast={podcast} />
        </Box>
      ))}
    </Box>
  );
};

export default PodcastList;
