import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, Input, Label, Select, Textarea, Checkbox } from "theme-ui";
import ErrorIcon from "../../assets/img/closed-circle.inline.svg";
import theme from "../../gatsby-plugin-theme-ui";
import { darken, lighten, getColor } from "@theme-ui/color";

const CustomInput = (props) => {
  const primary = getColor(theme, "primary");
  const error = getColor(theme, "error");
  const [passwordShown, setPasswordShown] = useState(false);
  const [focused, setFocused] = useState(false);
  const {
    id,
    onChange,
    validateField,
    name,
    rows,
    value,
    validate,
    placeholder,
    label = "",
    type = "text",
    variant,
    required,
    pattern,
    autocomplete = "off",
    events = {},
    icon,
    register,
    validationSchema,
    errors,
  } = props;

  const handleChange = (event) => {
    if (typeof onChange === "function") {
      onChange(event);
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      validateField(id);
    }
  }, [value, id]);

  useEffect(() => {
    console.log("focused", focused);
  }, [focused]);

  const fieldProps = {
    id,
    name,
    type,
    value,
    validate,
    placeholder,
    variant,
    required,
    pattern,
    autocomplete,
    register,
    validationSchema,
    onChange: handleChange,
  };

  if (type === "textarea") {
    delete fieldProps.type;
    delete fieldProps.value;

    fieldProps.defaultValue = value;
    fieldProps.rows = rows || 2;
  }

  return id ? (
    <Box sx={{ width: "100%" }}>
      <Flex
        sx={{
          width: "100%",
          flexDirection: "column",
          position: "relative",
          transition: "margin 200ms",
          "&:before": {
            content: "''",
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            zIndex: -1,
            mt: focused && "-3px",
            mb: "-3px",
            mr: focused && "-3px",
            ml: focused && "-3px",
            borderRadius: "inherit",
            background:
              type !== "checkbox" &&
              errors &&
              errors[name]?.type === "required" ||
              errors[name]?.type === "minLength" ||
              errors[name]?.type === "validate"
                ? `${error}`
                : type !== "checkbox" &&
                  `linear-gradient(to right, #01D7E2, #1E6BB1)`,
            transition: "margin 200ms",
          },
        }}
      >
        <Flex
          sx={{
            width: "100%",
            "input,select,textare": {
              pl: icon && [8],
              width: "100%",
              pr: type === "password" && [7],
            },
            "input[type='checkbox']": {
              width: "auto",
            },
            "& > div": { width: "100%" },
            position: "relative",
          }}
        >
          {icon && props.children}
          {type === "textarea" ? (
            <>
              <Textarea
                {...fieldProps}
                {...register(name, validationSchema)}
                variant={fieldProps.variant}
                sx={{
                  fontFamily: "body",
                }}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </>
          ) : type === "text" || type === "password" || type === "email" ? (
            <>
              <Input
                type={
                  type === "password" && passwordShown
                    ? "text"
                    : type === "password"
                    ? "password"
                    : type
                }
                {...fieldProps}
                {...register(name, validationSchema)}
                variant={fieldProps.variant}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            </>
          ) : type !== "checkbox" ? (
            <Select
              sx={{ width: "100%", minHeight: "54px" }}
              {...fieldProps}
              {...register(name, validationSchema)}
              variant={fieldProps.variant}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            >
              {props.children}
            </Select>
          ) : (
            <>
              <Box
                sx={{
                  ".container": {
                    display: "block",
                    position: "relative",
                    paddingLeft: "35px",
                    marginBottom: "12px",
                    cursor: "pointer",
                    fontSize: "22px",
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                  },
                  ".container input": {
                    position: "absolute",
                    opacity: 0,
                    cursor: "pointer",
                    height: "0",
                    width: "0",
                  },
                  ".checkmark": {
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: "0",
                    height: "23px",
                    width: "23px",
                    background: `linear-gradient(to right, #01D7E2, #1E6BB1)`,
                  },
                  ".container:hover input ~ .checkmark": {},
                  ".container input:checked ~ .checkmark": {
                    background: `linear-gradient(to right, #01D7E2, #1E6BB1)`,
                  },
                  ".checkmark:after": {
                    content: '""',
                    position: "absolute",
                    display: "none",
                  },
                  ".container input:checked ~ .checkmark:after": {
                    display: "block",
                  },
                  ".container input:checked ~ .checkmark:before": {
                    background: "unset",
                  },
                  ".container .checkmark:after": {
                    left: "8px",
                    top: "3px",
                    width: "5px",
                    height: "10px",
                    border: "solid white",
                    borderWidth: "0 3px 3px 0",
                    WebkitTransform: "rotate(45deg)",
                    msTransform: "rotate(45deg)",
                    transform: "rotate(45deg)",
                  },
                  ".container .checkmark:before": {
                    content: "''",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                    zIndex: -1,
                    mt: "3px",
                    mb: "3px",
                    mr: "3px",
                    ml: "3px",
                    borderRadius: "inherit",
                    background: `dark`,
                    transition: "margin 200ms",
                  },
                }}
              >
                <Label
                  className="container"
                  for={fieldProps.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "text",
                    svg: {
                      width: "20px",
                      height: "20px",
                      color: "primary",
                      backgroundColor: "unset",
                    },
                    mt: [3, 3, 3, 3],
                  }}
                >
                  <Box
                    sx={{
                      p: {
                        m: [0],
                        fontSize: ["12px"],
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: label }}
                  />
                  <input
                    type="checkbox"
                    sx={{ width: "100%", minHeight: "54px" }}
                    {...fieldProps}
                    {...register(name, validationSchema)}
                    variant={fieldProps.variant}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                  />
                  <span class="checkmark"></span>
                </Label>
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      <>
        {errors && errors[name]?.type === "required" && (
          <Flex sx={{ my: [2], color: "primary", alignItems: "center" }}>
            <Flex sx={{ mr: 1, alignItems: "center" }}>
              <ErrorIcon />
            </Flex>
            <span className="error">{errors[name]?.message}</span>
          </Flex>
        )}
        {errors && errors[name]?.type === "minLength" && (
          <Flex sx={{ my: [2], color: "primary", alignItems: "center" }}>
            <Flex sx={{ mr: 1, alignItems: "center" }}>
              <ErrorIcon />
            </Flex>
            <span className="error">{errors[name]?.message}</span>
          </Flex>
        )}
        {errors && errors[name]?.type === "validate" && (
          <Flex sx={{ my: [2], color: "primary", alignItems: "center" }}>
            <Flex sx={{ mr: 1, alignItems: "center" }}>
              <ErrorIcon />
            </Flex>
            <span className="error">{errors[name]?.message}</span>
          </Flex>
        )}
      </>
    </Box>
  ) : (
    ""
  );
};

export default CustomInput;
