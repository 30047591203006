import React, { useEffect, useContext } from "react";
import { Box, Container } from "theme-ui";
import PodcastList from "./List";
import PodcastPlayer from "./Player";
import { CurrentPodcastProvider } from "../../hooks/currentPodcastContext";
import CurrentPodcastContext from "../../hooks/currentPodcastContext";

const PodcastCollection = ({ block }) => {
  const { currentPodcast, setCurrentPodcast } = useContext(
    CurrentPodcastContext
  );

  const { podcasts } = block;

  useEffect(() => {
    setCurrentPodcast({ ...podcasts.reverse()[0], play: false });
  }, []);

  return (
    <Box sx={{pb:[6,10]}}>
      <Container>
        {/* <Box sx={{mb:[6,8]}}>
          <PodcastPlayer />
        </Box> */}
        {podcasts.length > 1 && <PodcastList podcasts={podcasts} />}
      </Container>
    </Box>
  );
};

export default PodcastCollection;
