import React, { useEffect, useState } from "react";
import { Box, Container, Flex, Text, Grid } from "@theme-ui/components";
import { ChevronDown, ChevronUp, Menu, X } from "react-feather";
import { motion, AnimatePresence } from "framer-motion";
import { getColor } from "@theme-ui/color";
import themeUiTheme from "../../gatsby-plugin-theme-ui";

import AppleIcon from "../../assets/img/_canali/_footer/apple_f.inline.svg";
import IheartIcon from "../../assets/img/_canali/_footer/iheart_f.inline.svg";
import SpotifyIcon from "../../assets/img/_canali/_footer/spotify_f.inline.svg";
import SpreakerIcon from "../../assets/img/_canali/_footer/spreaker_f.inline.svg";

import * as Scroll from "react-scroll";
import { Link } from "react-scroll";
import { useBreakpointIndex } from "@theme-ui/match-media";

const MobileNav = ({ company, theme = "light" }) => {
  const isBrowser = typeof window != "undefined" && window.document;
  const primary = getColor(themeUiTheme, "primary");
  const dark = getColor(themeUiTheme, "dark");
  const light = getColor(themeUiTheme, "light");
  const [show, setShow] = useState(false);

  const mediaIndex = useBreakpointIndex();

  const {
    title,
    description,
    copyright,
    privacy,
    cookie,
    legalInfo,
    spotifyUrl,
    appleUrl,
    spreakerUrl,
    iheartUrl,
  } = company;

  const sideVariants = {
    closed: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: 1,
      },
    },
  };

  useEffect(() => {
    if (isBrowser) {
      // Applying on mount
      if (show) document.body.style.overflow = "hidden";
      // Applying on unmount
      if (!show) document.body.style.overflow = "visible";
    }
  }, [show]);

  useEffect(() => {
    if (mediaIndex > 1) {
      setShow(false);
      document.body.style.overflow = "visible";
    }
  }, [mediaIndex]);

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Container
        sx={{
          position: "fixed",
          zIndex: 999999,
          top: 0,
          height: "50px",
          overflow: "hidden",
          maxHeight: "50px",
          alignItems: "center",
          paddingY: [2, 3],
        }}
      >
        <Flex
          sx={{
            position: "relative",
            zIndex: 999,
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box onClick={() => setShow(!show)}>
            <Hamburger show={show} />
          </Box>
        </Flex>
      </Container>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: "0",
              left: 0,
              zIndex: 100,
              overflowY: "hidden",
              height: "0",
            }}
            animate={{
              backgroundColor: primary,
              height: "100%",
              overflowY: "auto",
            }}
            exit={{
              overflowY: "hidden",
              height: "0",
            }}
            transition={{ type: "linear", stiffness: 1, delayChildren: 0.5 }}
          >
            <motion.div
              className="container"
              initial={"closed"}
              animate={"open"}
              exit={"closed"}
              variants={sideVariants}
            >
              {console.log("enters")}
              <Box sx={{ px: [0, 0, 0, 3], pt: [9, 9], pb: [0, 0] }}>
                <Flex
                  sx={{
                    flexDirection: "column",
                    padding: 0,
                    margin: 0,
                    px: [3, 4],
                    listStyle: "none",
                    li: {
                      pb: [4],
                      a: {
                        fontSize: [7],
                        textTransform: "uppercase",
                        textDecoration: "none",
                        fontWeight: "600",
                      },
                    },
                  }}
                  as="ul"
                >
                  <Box as="li">
                    <Link
                      onClick={() => {
                        setShow(!show);
                        if (
                          typeof window !== "undefined" &&
                          window.dataLayer !== undefined
                        ) {
                          window.dataLayer.push({
                            event: "clic_episodi",
                          });
                        }
                      }}
                      activeClass="header_link_active"
                      to="podcast_collection"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                    >
                      Episodi
                    </Link>
                  </Box>

                  <Box as="li">
                    <Link
                      onClick={() => {
                        setShow(!show);
                        if (
                          typeof window !== "undefined" &&
                          window.dataLayer !== undefined
                        ) {
                          window.dataLayer.push({
                            event: "clic_ospiti",
                          });
                        }
                      }}
                      activeClass="header_link_active"
                      to="guest_collection"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                    >
                      Ospiti&Speaker
                    </Link>
                  </Box>

                  <Box as="li">
                    <Link
                      onClick={() => {
                        setShow(!show);
                        if (
                          typeof window !== "undefined" &&
                          window.dataLayer !== undefined
                        ) {
                          window.dataLayer.push({
                            event: "clic_contattaci",
                          });
                        }
                      }}
                      activeClass="header_link_active"
                      to="contact_form"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                    >
                      CONTATTACI
                    </Link>
                  </Box>

                  <Box as="li" sx={{ pb: [10] }}>
                    <Box
                      onClick={() => {
                        setShow(!show);
                      }}
                      as="a"
                      sx={{ color: "dark" }}
                      target="_blank"
                      href="https://www.telmotor.it/"
                    >
                      telmotor >
                    </Box>
                  </Box>
                </Flex>
                <Box sx={{ mt: [6], px: [3, 4] }}>
                  <Box
                    sx={{
                      pb: [4],
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: [7],

                        textTransform: "uppercase",
                        textDecoration: "none",
                        fontWeight: "400",
                      }}
                    >
                      Seguici su
                    </Text>
                  </Box>
                  <Grid
                    columns={["1fr 1fr"]}
                    sx={{ justifyContent: "end" }}
                    gap={[0]}
                  >
                    <PodcastButton link={spotifyUrl}>
                      <SpotifyIcon />
                    </PodcastButton>
                    <PodcastButton link={appleUrl}>
                      <AppleIcon />
                    </PodcastButton>
                    <PodcastButton link={spreakerUrl}>
                      <SpreakerIcon />
                    </PodcastButton>
                    <PodcastButton link={iheartUrl}>
                      <IheartIcon />
                    </PodcastButton>
                  </Grid>
                </Box>
              </Box>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

const PodcastButton = ({ children, link }) => {
  return (
    <Box
      sx={{
        mb: ["-3px"],
        mr: ["-3px"],
        a: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          py: [3],
          px: [3, 5],
          border: "3px solid",
          borderColor: "light",
          svg: {
            maxHeight: ["35px", "50px"],
            height: ["35px", "50px"],
            width: "auto",
          },
        },
      }}
    >
      <a target="_blank" href={link}>
        {children}
      </a>
    </Box>
  );
};

const Hamburger = ({ show }) => {
  return (
    <Box
      className={show ? "show" : ""}
      sx={{
        // height:"22px",
        // width: "42px",
        // position:"relative",
        // "&:before,&:after,& div": {
        //   borderRadius: "sm",
        //   position:"absolute",
        //   content: "''",
        //   backgroundColor: show ? "light" : "primary",
        //   display: "block",
        //   height: "2px",
        //   transition: ".55s",
        // },
        // "&:before": {
        //   top:0,
        //   width: "60%",
        // },
        // "& div": {
        //   top:[2],
        //   width: "100%",
        //   right:0,
        // },
        // "&:after": {
        //   top:[3],
        //   width: "60%",
        // },

        // "&.show:before": {
        //   width:"100%"
        // },
        // "&.show div": {
        //   width:"60%",
        //   right:0,
        // },
        // "&.show:after": {
        //   width:"100%"
        // },

        width: "25px",
        "&:before,&:after,& div": {
          borderRadius: "sm",
          content: "''",
          backgroundColor: "light",
          display: "block",
          height: "3px",
          my: [1],
          transition: "transform .3s ease-in-out, opacity .0s ease-in-out",
        },

        "&.show:before": {
          transform: "translateY(8px) rotate(135deg)",
        },
        "&.show:after": {
          transform: "translateY(-6px) rotate(-135deg)",
        },
        "&.show div": {
          opacity: 0,
          transform: "scale(0)",
        },
      }}
    >
      <Box></Box>
    </Box>
  );
};

export default MobileNav;
