import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  Input,
  Text,
  Textarea,
  Label,
  Button,
  Grid,
  Flex,
} from "theme-ui";
import CustomInput from "./customInput";
import axios from "axios";
import SoundWaveLoader from "../SoundWaveLoader";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ContactForm = ({ block }) => {
  const { kicker, title, privacyPolicy, newsletterAgreement } = block;
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    newsletter: null,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm();

  const handleSupportMail = async (event) => {
    const data = formData;

    setLoading(true);

    const result = await executeRecaptcha("dynamicAction");

    if (result)
      axios
        .post("/.netlify/functions/sendMail", data)
        .then(function (response) {
          setSuccess(true);
          setLoading(false);

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "form_submit",
            });
          }
        })
        .catch(function (error) {
          setSuccess(false);
          setLoading(false);
        });
    else {
      setSuccess(false);
      setLoading(false);
    }
  };

  const onUpdateField = (e) => {
    console.log(
      "onUpdateField",
      e.target.name,
      e.target.value,
      e.target.checked
    );
    const nextFormState = {
      ...formData,
      [e.target.name]:
        e.target.type != "checkbox" ? e.target.value : e.target.checked,
    };
    setFormData(nextFormState);
  };

  return (
    <Box>
      <Container sx={{ py: [4, 4, 11] }}>
        <Grid columns={["1fr", "1fr", ".4fr .6fr"]} gap={[0, 0, 8, 12]}>
          <Box sx={{ pb: [6, 6, 0] }}>
            <Text
              as="div"
              dangerouslySetInnerHTML={{ __html: title }}
              sx={{
                p: {
                  color:"primary",
                  my: [0],
                  mt: ["24px"],
                  fontSize: [4, 6, 6, "42px"],
                  lineHeight: "1.071",
                  fontWeight: "500",
                },
              }}
            />
          </Box>
          <>
            <Box
              sx={{ pb: [6] }}
              as="form"
              onSubmit={handleSubmit(handleSupportMail)}
            >
              <Grid
                sx={{
                  flexWrap: "wrap",
                  flexDirection: "column",
                }}
                gap={[0, 0, 0, 0]}
              >
                <Box
                  sx={{
                    pb: [3, 3, 3, 3],
                    width: ["100%", "100%"],
                  }}
                >
                  <CustomInput
                    id="fullname"
                    label="Nome e Cognome*"
                    type="text"
                    name="fullName"
                    placeholder="Nome e Cognome*"
                    variant="inputs.primary"
                    register={register}
                    errors={errors}
                    validationSchema={{
                      required: "Questo campo è obbligatorio.",
                      onChange: (e) => onUpdateField(e),
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    pr: [0, 1, 1, 1],
                    pb: [3, 3, 3, 3],
                    width: ["100%", "100%"],
                  }}
                >
                  <CustomInput
                    id="email"
                    label="Email*"
                    type="email"
                    name="email"
                    placeholder="Email*"
                    variant="inputs.primary"
                    register={register}
                    errors={errors}
                    validationSchema={{
                      required: "Questo campo è obbligatorio.",
                      onChange: (e) => onUpdateField(e),
                    }}
                  />
                </Box>
                {/* <Box
                  sx={{
                    width: ["100%", "100%"],
                  }}
                >
                  <CustomInput
                    id="privacy"
                    label={privacyPolicy}
                    type="checkbox"
                    name="privacy"
                    register={register}
                    errors={errors}
                    validationSchema={{
                      required: "Questo campo è obbligatorio.",
                      onChange: (e) => onUpdateField(e),
                    }}
                  />
                </Box> */}

                <Box
                  sx={{
                    pb: [3, 3, 3, 3],
                    width: ["100%", "100%"],
                  }}
                >
                  <CustomInput
                    id="newsletter"
                    label={privacyPolicy}
                    type="checkbox"
                    name="newsletter"
                    register={register}
                    errors={errors}
                    required={false}
                    validationSchema={{
                      required: "Questo campo è obbligatorio.",
                      onChange: (e) => onUpdateField(e),
                    }}
                  />
                </Box>
              </Grid>
              <Box
                sx={{
                  textAlign: "right",
                }}
              >
                <Button
                  sx={{
                    height: "",
                    width: ["160px"],
                    textAlign: "center",
                    fontSize: [3],
                    fontWeight: "600",
                    borderRadius: "unset",
                    p: [3],
                    position: "relative",
                    "&:before": {
                      content: "''",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      bottom: "0",
                      left: "0",
                      zIndex: -1,
                      mt: "-3px",
                      mb: "-3px",
                      mr: "-3px",
                      ml: "-3px",
                      borderRadius: "inherit",
                      background: `linear-gradient(to right, #01D7E2, #1E6BB1)`,
                    },
                  }}
                  variant="buttons.primary"
                  disabled={loading}
                >
                  <Box sx={{}}>
                    {!loading ? (
                      <Text
                        sx={{
                          color: "primary",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        Invia
                      </Text>
                    ) : (
                      <Text
                        sx={{
                          color: "primary",
                          fontWeight: "600",
                          textTransform: "uppercase",
                        }}
                      >
                        <SoundWaveLoader />
                      </Text>
                    )}
                  </Box>
                </Button>
                {success === true ? (
                  <Flex sx={{ maxWidth: "600px" }}>
                    <Heading sx={{ my: [4], color: "primary" }} as="h5">
                      Richiesta inviata! Ti ricontatteremo al più presto.
                    </Heading>
                  </Flex>
                ) : (
                  success === false && (
                    <Flex sx={{ maxWidth: "600px" }}>
                      <Heading sx={{ my: [4], color: "errorflat" }} as="h5">
                        Qualcosa è andato storto
                      </Heading>
                    </Flex>
                  )
                )}
              </Box>
            </Box>
          </>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactForm;