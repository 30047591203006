import React from "react";
import { Box, Flex } from "theme-ui";
import * as Scroll from "react-scroll";
import { Link } from "react-scroll";

const Nav = () => {
  return (
    <Box>
      <Flex
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          py: [0, 6, 7],
          a: {
            textDecoration: "none",
            cursor: "pointer",
          },
        }}
      >
        <Box
          as="ul"
          sx={{
            display: "flex",
            listStyle: "none",
            pl: 0,
            a: {
              "&:hover": {
                color: "primary",
              },
            },
          }}
        >
          <ListItem>
            <Link
              onClick={() => {
                if (
                  typeof window !== "undefined" &&
                  window.dataLayer !== undefined
                ) {
                  window.dataLayer.push({
                    event: "clic_episodi",
                  });
                }
              }}
              activeClass="header_link_active"
              to="podcast_collection"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Episodi
            </Link>
          </ListItem>
          <ListItem>
            <Link
              onClick={() => {
                if (
                  typeof window !== "undefined" &&
                  window.dataLayer !== undefined
                ) {
                  window.dataLayer.push({
                    event: "clic_ospiti",
                  });
                }
              }}
              activeClass="header_link_active"
              to="guest_collection"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Ospiti&Speaker
            </Link>
          </ListItem>
          <ListItem>
            <Link
              onClick={() => {
                if (
                  typeof window !== "undefined" &&
                  window.dataLayer !== undefined
                ) {
                  window.dataLayer.push({
                    event: "clic_contattaci",
                  });
                }
              }}
              activeClass="header_link_active"
              to="contact_form"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              CONTATTACI
            </Link>
          </ListItem>
        </Box>
        <Box>
          <Box
            sx={{
              px: [4],

              fontSize: [2],
              textTransform: "uppercase",
              fontWeight: "600",
              lineHeight: "1.8",
              a: {
                color: "primary",

                "&:hover": {
                  color: "secondary",
                },
              },
            }}
          >
            <a target="_blank" href="https://www.telmotor.it/">
              scopri telmotor >
            </a>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

const ListItem = ({ children }) => {
  return (
    <Box
      as="li"
      sx={{
        px: [4],
        color: "light",
        fontSize: [2],
        textTransform: "uppercase",
        fontWeight: "600",
        lineHeight: "1.8",
      }}
    >
      {children}
    </Box>
  );
};

export default Nav;
